import React from "react"

import Layout from "../components/common/layout"
import SEO from "../components/common/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>Wrong turn</h1>
    <p>You seemed to be lost.</p>
  </Layout>
)

export default NotFoundPage
